import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { RedFancyButton, RedRectangularButton } from "../Buttons.js"
import Img from "gatsby-image"

const HomeBannerBgImage = styled(BackgroundImage)`
  min-height: 700px;
  position: relative;
  background-position: ${props =>
    props.secondaryPage === true ? `25% 0` : `0 0`};
  background-color: #0f2038;

  @media (min-width: 992px) {
    background-attachment: fixed;
    :before,
    :after {
      background-attachment: fixed;
    }
    background-position: left top;
  }

  @media (min-width: 1367px) {
    min-height: 892px;
  }
`
const LawyersImg = styled(Img)`
  width: 100%;
  z-index: -1;
  top: 12%;
  @media (min-width: 768px) {
    top: 25%;
  }
  @media (min-width: 992px) {
    top: 10%;
    width: 60vw;
    left: -25%;
    transform: translateX(25%);
  }
  @media (min-width: 1367px) {
    top: 15%;
  }
  @media (min-width: 1920px) {
    top: 10%;
  }
`

const DownArrow = styled(Img)``
const DownArrowContainer = styled.div`
  ${DownArrow}[style] {
    display: none !important;
    @media (min-width: 768px) {
      display: inline !important;
      position: absolute !important;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
`
const BannerTextContainer = styled.div`
  padding: 149px 0 0 0;

  ${LawyersImg}[style] {
    position: absolute !important;
    left: 0;
  }
`

const BannerText = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding-bottom: 40px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(3, 8, 18, 0) 0%,
    rgba(4, 10, 21, 1) 9%,
    rgba(9, 20, 37, 1) 51%,
    rgba(14, 31, 56, 0) 100%
  );
  text-align: center;
  @media (min-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      rgba(3, 8, 18, 0) 0%,
      rgba(9, 20, 37, 1) 51%,
      rgba(14, 31, 56, 0) 100%
    );
  }
  @media (min-width: 992px) {
    background-image: linear-gradient(
      to bottom,
      rgba(3, 8, 18, 0) 0%,
      rgba(9, 20, 37, 1) 51%,
      rgba(14, 31, 56, 1) 100%
    );
  }
`
const BannerTextBlue = styled.div`
  position: absolute;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(3, 8, 18, 0), #0e1f38, #0e1f38);
  z-index: -1; /* Specify a stack order in case you're using a different order for other elements */
  @media (min-width: 768px) {
    display: none;
  }
`
const BannerTextLineOne = styled.p`
  font-size: 20px;
  position: relative;
  font-family: "Playfair Display";
  font-weight: 400;
  margin: 0;
  padding: 0 15px;
  z-index: 4;
  margin: 0 auto;
  text-align: left;
  @media (min-width: 576px) {
    width: 62%;
    font-size: calc(20px + 20 * ((100vw - 576px) / 1344));
  }
  @media (min-width: 992px) {
    font-size: 40px;
  }
`
const Line = styled.span`
  width: 66px;
  display: inline-block;
  height: 1px;
  background: #fff;
  margin-left: 10px;
  position: relative;
  top: -10px;
`
const BannerH3 = styled.h3`
  font-size: 62px;
  margin: 8px auto;
  font-weight: 700;
  color: #fff;
  padding: 0 15px;
  z-index: 4;
  @media (min-width: 576px) {
    width: 62%;
    font-size: calc(62px + 58 * ((100vw - 576px) / 1344));
  }
  @media (min-width: 992px) {
    font-size: 120px;
  }
`
const LineYellow = styled(Line)`
  background: #f1d1a2;
  margin-right: 10px;
  margin-left: 0;
`
const BannerTextLineThree = styled(BannerTextLineOne)`
  text-transform: uppercase;
  color: #f1d1a2;
  text-align: right;
`
const ReviewsCircle = styled.div`
  width: 150px;
  margin: 20px auto 40px auto;
  border-radius: 50%;
  height: 150px;
  text-align: center;
  border: 1px solid #fff;
  background-color: #0c182d;
  z-index: 4;

  iframe {
    border-width: 0px;
    display: block;
    margin: 0px auto;
    overflow: hidden;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const RedFancyButtonStyled = styled(RedFancyButton)`
  @media (min-width: 992px) {
    margin-top: 20px;
  }
`
const RedRectangularButtonStyled = styled(RedRectangularButton)`
  margin-left: 26px;
  margin-right: 26px;
  @media (min-width: 768px) {
    width: 62%;
    margin: 40px auto 0 auto;
  }
  @media (min-width: 992px) {
    width: 18.75rem;
  }
`

const LinkStyled = styled(Link)`
  display: inline-block;
`

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url ? url.replace("https://backend-gatsby.mmklawfirm.com", ``) : url
}
const HomeBanner = ({ title }) => {
  const breakpoints = useBreakpoint()
  const BANNER_QUERY = graphql`
    {
      wpgraphql {
        page(id: "cGFnZToyNg==") {
          Homepage {
            bannerTextLineOne
            bannerTextH3
            bannerTextLineThree
            bannerButtonText
            bannerButtonLink {
              url
            }
            bannerFreeConsultationButtonText
            bannerFreeConsultationButtonLink {
              url
            }
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "main_slider_bg.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alternateBackgroundImage: file(
        relativePath: { eq: "alternate-ad-campaign-image2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      foregroundImage: file(relativePath: { eq: "New-Group-Photo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      downArrow: file(relativePath: { eq: "down_arrow.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={BANNER_QUERY}
      render={props => (
        <>
          <HomeBannerBgImage
            secondaryPage={
              title !== "Slip and Fall Attorneys Pennsylvania 2" ? false : true
            }
            fluid={
              title !== "Slip and Fall Attorneys Pennsylvania 2"
                ? props.backgroundImage.childImageSharp.fluid
                : props.alternateBackgroundImage.childImageSharp.fluid
            }
            loading="eager"
            fadeIn="false"
          >
            <BannerTextContainer>
              {title !== "Slip and Fall Attorneys Pennsylvania 2" && (
                <LawyersImg
                  fluid={props.foregroundImage.childImageSharp.fluid}
                  loading="eager"
                />
              )}

              <BannerText>
                <BannerTextBlue></BannerTextBlue>
                <BannerTextLineOne>
                  {props.wpgraphql.page.Homepage.bannerTextLineOne}
                  <Line />
                </BannerTextLineOne>

                <BannerH3>
                  {props.wpgraphql.page.Homepage.bannerTextH3}
                </BannerH3>

                <BannerTextLineThree>
                  <LineYellow />
                  {props.wpgraphql.page.Homepage.bannerTextLineThree}
                </BannerTextLineThree>

                <RedRectangularButtonStyled
                  to={createLocalLink(
                    props.wpgraphql.page.Homepage
                      .bannerFreeConsultationButtonLink.url
                  )}
                >
                  {
                    props.wpgraphql.page.Homepage
                      .bannerFreeConsultationButtonText
                  }
                </RedRectangularButtonStyled>
              </BannerText>
            </BannerTextContainer>
          </HomeBannerBgImage>
          <DownArrowContainer>
            <DownArrow fixed={props.downArrow.childImageSharp.fixed} />
          </DownArrowContainer>
        </>
      )}
    />
  )
}
export default HomeBanner
